<template>
  <figure
    class="data-bloc"
    :class="[
      variant,
      color,
      { small, },
    ]"
  >
    <template v-if="variant !== 'icon-with-number'">
      <!-- @slot Emplacement pour une icône. -->
      <slot name="icon" />
      <strong class="s4 number">
        <!-- @slot Emplacement pour un nombre avec éventuellement se devise. -->
        <slot name="number" />
      </strong>
    </template>

    <div
      v-else
      class="icon-number flex-vcenter"
    >
      <!-- @slot Emplacement pour une icône. -->
      <slot name="icon" />
      <strong class="number s4">
        <!-- @slot Emplacement pour un nombre avec éventuellement se devise. -->
        <slot name="number" />
      </strong>
    </div>

    <figcaption
      class="label light"
      :class="small ? 'text-regular' : 's5'"
      v-html="label"
    />
  </figure>
</template>

<script>
/**
 * Affiche un texte avec un compteur et une icône associée.
 * **Note :** La couleur de fond est à changer dans le parent.
 */
export default {
  name: "DataBloc",
  props: {
    /**
     * Légende du bloc.
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Couleur de l'icône et du nombre associé.
     */
    color: {
      type: String,
      validator: (value) => [
        "white",
        "blue",
        "info",
        "positive",
        "warning",
        "negative",
        "gray",
        "black",
      ].includes(value),
      default: "white",
    },
    /**
     * Change l'affichage des éléments.
     */
    variant: {
      type: String,
      validator: (value) => [null, "horizontal", "icon-with-number"].includes(value),
      default: null,
    },
    /**
     * Réduit la taille de la police et de l'image.
     */
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/establishments/data_bloc.scss";
</style>
