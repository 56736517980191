import Api from "@/modules/axios";

import { mapGetters } from "vuex";

export default {
  emits: [
    /**
     * Déclenché lors du chagement d'image.
     */
    "change-img",
  ],
  computed: {
    ...mapGetters(["organismeActuel"]),
  },
  methods: {
    /**
     * Change l'image de l'établissement.
     * @param {Object} imgFromModal Image sélectionnée dans la modale (proposée ou upload).
     */
    changeImage(imgFromModal, organisme) {
      let formdata = null;

      if (imgFromModal.checkedImg) {
        formdata = { image_from_library: imgFromModal.checkedImg };
      } else if (imgFromModal.uploadedFile) {
        formdata = new FormData();
        formdata.append("image", imgFromModal.uploadedFile);
      }

      if (formdata) {
        Api().post(`/organisme/${organisme.id}/upload_image/`, formdata)
          .then((res) => {
            if (this.organismeActuel.id === organisme.id) {
              this.$store.commit("setImgEtablissement", res.data.infos);
            }

            this.$modal.hide("modal_change_image");
            this.$toast.success({ title: this.$t("modal-change.image-change-succes") });
          })
          .catch(() => {
            this.$toast.error({ title: this.$t("info.une-erreur-est-survenue") });
          });
      }
    },
  },
};
